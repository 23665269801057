import React, { useState } from 'react';

import styled from 'styled-components';

import ImgCommandBlock from '../../assets/command-block.png';
import ImgGrassBlock from '../../assets/grass-block.png';
import { useNavigate } from 'react-router-dom';

const DivCard = styled.div`
  background: #ffffffcc;
  width: 70%;
  margin: auto;
  border-radius: 25px;
  padding: 30px 40px;
`;

const DivCardParent = styled.div`
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

const DivCardChild = styled.div`
  border: 1px solid #bababa;
  border-radius: 25px;
  flex: 1;
  padding: 35px 30px;
  display: flex;
  gap: 20px;
  align-items: center;
  &:hover {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }
  cursor: pointer;
  position: relative;
`;

const ButtonPrimary = styled.button`
  background: #1233b5;
  border-radius: 25px;
  margin-top: 45px;
  font-size: 30px;
  border: none;
  color: white;
  width: 250px;
  max-width: 100%;
  height: 65px;
  cursor: pointer;
  &:hover {
    background: #001a83;
  }
  &:disabled {
    background: #a9a9a9;
  }
`;

function CardChoice() {
  const [radioChecked, setRadioChecked] = React.useState([]);
  const [switchSelected, setSwitchSelected] = React.useState(false);

  let navigate = useNavigate();
  const routeChange = () => {
    let path = radioChecked === 'seeds' ? `seeds` : `commands`;
    navigate(path, { state: { isJava: !switchSelected } });
  };

  return (
    <DivCard>
      <div className="display-flex justify-space-between mobile-column mobile-text-center">
        <p className="fs-20 font-semibold">
          Choisit ton mode de divertissement
        </p>
        <div className="display-flex gap-15 mobile-column">
          <p className="fs-20 font-semibold">Java</p>
          <label className="switch mobile-margin-auto">
            <input
              type="checkbox"
              checked={switchSelected}
              onChange={() => setSwitchSelected(!switchSelected)}
            ></input>
            <span className="slider round"></span>
          </label>

          <p className="fs-20 font-semibold">Bedrock</p>
        </div>
      </div>

      <DivCardParent className="display-flex justify-space-between margin-top-25 gap-35">
        <DivCardChild onClick={() => setRadioChecked('commands')}>
          <label className="container">
            <input
              type="radio"
              checked={radioChecked === 'commands'}
              readOnly
            ></input>
            <span className="checkmark"></span>
          </label>
          <img className="mobile-hide" src={ImgCommandBlock}></img>
          <div>
            <p className="fs-25 font-semibold word-break">Commande</p>
            <p className="fs-18 font-medium margin-top-25 word-break">
              Récupère une commande utile, drôle, wtf, .. et essaye la en jeu !
            </p>
          </div>
        </DivCardChild>
        <DivCardChild onClick={() => setRadioChecked('seeds')}>
          <label className="container">
            <input
              type="radio"
              checked={radioChecked === 'seeds'}
              readOnly
            ></input>
            <span className="checkmark"></span>
          </label>
          <img className="mobile-hide" src={ImgGrassBlock}></img>
          <div>
            <p className="fs-25 font-semibold word-break">Seeds</p>
            <p className="fs-18 font-medium margin-top-25 word-break">
              Récupère une seeds unique, mieux que celle de base avec des
              particularités et essaye la en jeu !
            </p>
          </div>
        </DivCardChild>
      </DivCardParent>

      <div className="text-align-center">
        <ButtonPrimary
          className="font-semibold"
          disabled={radioChecked !== 'seeds' && radioChecked !== 'commands'}
          onClick={routeChange}
        >
          Récupérer
        </ButtonPrimary>
      </div>
    </DivCard>
  );
}

export default CardChoice;
