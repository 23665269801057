import styled from 'styled-components';

const TextTitle = styled.p`
  color: ${(props) => (props.isBackgroundDay === true ? 'black' : 'white')};
  transition: all 0.7s linear;
  word-break: break-word;
`;

function Title({ isBackgroundDay }) {
  return (
    <div>
      <TextTitle
        className="fs-80 text-align-center font-extrabold"
        isBackgroundDay={isBackgroundDay}
      >
        Amuse toi différemment
      </TextTitle>
      <TextTitle
        className="fs-35 text-align-center font-semibold margin-top-15"
        isBackgroundDay={isBackgroundDay}
      >
        Découvre une commande ou une seed
      </TextTitle>
    </div>
  );
}

export default Title;
