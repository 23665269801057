import styled from 'styled-components';

import ImgSign from '../../assets/sign.png';
import ImgCommandBlock from '../../assets/command-block.png';

import { useNavigate, useLocation } from 'react-router-dom';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';

import React, { useState } from 'react';

const DivCard = styled.div`
  background: #ffffffcc;
  width: 70%;
  margin: auto;
  border-radius: 25px;
  padding: 30px 40px;
`;

const DivCardParent = styled.div`
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

const DivCardChild = styled.div`
  border: solid 1px #1233b5;
  color: black;
  border-radius: 25px;
  flex: 1;
  width: 100%;
  padding: 25px 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
`;

const ButtonPrimary = styled.button`
  background: #1233b5;
  border-radius: 25px;
  margin-top: 45px;
  font-size: 30px;
  border: none;
  color: white;
  width: 250px;
  max-width: 100%;
  height: 65px;
  cursor: pointer;
  &:hover {
    background: #001a83;
  }
`;

const ButtonOutline = styled.button`
  background: none;
  border-radius: 25px;
  margin-top: 45px;
  font-size: 30px;
  border: solid 1px black;
  color: black;
  width: 250px;
  max-width: 100%;
  height: 65px;
  cursor: pointer;
  &:hover {
    background: #ffffff;
  }
`;

const commandsJava = [
  {
    text: '/give @p minecraft:netherite_sword{Enchantments:[{id:sharpness,lvl:255}]} 1',
    info: 'Cela vous donne une épée en netherite avec tranchant 255',
  },
  {
    text: '/give @p minecraft:stick{Enchantments:[{id:knockback,lvl:255}]} 1',
    info: 'Cela vous donne un batôn avec recul 255',
  },
  {
    text: '/give @p minecraft:netherite_pickaxe{Unbreakable:100,Enchantments:[{id:fortune,lvl:255}]} 1',
    info: 'Cela vous donne une pioche en netherite avec fortune 255',
  },
  {
    text: '/give @p minecraft:netherite_sword{Enchantments:[{id:looting,lvl:255}]} 1',
    info: 'Cela vous donne une épée en netherite avec butin 255',
  },
  {
    text: '/give @p minecraft:netherite_chestplate{Enchantments:[{id:protection,lvl:255}]} 1',
    info: 'Cela vous donne un plastron en netherite avec protection 255',
  },
  {
    text: '/give @p minecraft:netherite_pickaxe{Enchantments:[{id:efficiency,lvl:255}]} 1',
    info: 'Cela vous donne une pioche en netherite avec efficacité 255',
  },
  {
    text: '/give @p minecraft:netherite_chestplate{Enchantments:[{id:thorns,lvl:255}]} 1',
    info: 'Cela vous donne une armure en netherite avec épine 255',
  },
  {
    text: '/playsound minecraft:ambient.cave hostile @p',
    info: 'Joue un son qui fait peur autour de vous',
  },
  {
    text: '/summon minecraft:fireball ~ ~3 ~ {ExplosionPower:50,direction:[1.0,0.0,0.0]}',
    info: 'Invoque une giga boule de feu à pousser (attention à vous)',
  },
  {
    text: '/summon minecraft:end_crystal ~ ~ ~',
    info: "Invoque une pierre de l'end, sympa pour la déco",
  },
  {
    text: '/give @p tnt{BlockStateTag:{unstable:"true"}} 1',
    info: "Donne une TNT qui explose au lieu de se casser quand on la tape",
  },
  {
    text: '/summon minecraft:pig ~ ~1 ~ {Passengers:[{id:"minecraft:phantom",Silent:1b,NoAI:1b}]}',
    info: "Invoque un cochon avec des ailes",
  },
  {
    text: '/summon minecraft:slime ~ ~ ~ {Size:100}',
    info: "Invoque un slime géant",
  },
  {
    text: '/summon minecraft:giant',
    info: "Invoque un zombie géant",
  },
  {
    text: '/summon minecraft:creeper ~ ~1 ~ {powered:1}',
    info: "Invoque un creeper electrifié",
  },
  {
    text: '/summon minecraft:ender_dragon ~ ~1 ~ {Passengers:[{id:"minecraft:wither"}]}',
    info: "Invoque un giga boss.. bonne chance",
  },
];

const commandsBedrock = [    
    {
      text: '/give @p minecraft:netherite_sword{Enchantments:[{id:sharpness,lvl:255}]} 1',
      info: 'Cela vous donne une épée en netherite avec tranchant 255',
    },
    {
      text: '/give @p minecraft:stick{Enchantments:[{id:knockback,lvl:255}]} 1',
      info: 'Cela vous donne un batôn avec recul 255',
    },
    {
      text: '/give @p minecraft:netherite_pickaxe{Unbreakable:100,Enchantments:[{id:fortune,lvl:255}]} 1',
      info: 'Cela vous donne une pioche en netherite avec fortune 255',
    },
    {
      text: '/give @p minecraft:netherite_sword{Enchantments:[{id:looting,lvl:255}]} 1',
      info: 'Cela vous donne une épée en netherite avec butin 255',
    },
    {
      text: '/give @p minecraft:netherite_chestplate{Enchantments:[{id:protection,lvl:255}]} 1',
      info: 'Cela vous donne un plastron en netherite avec protection 255',
    },
    {
      text: '/give @p minecraft:netherite_pickaxe{Enchantments:[{id:efficiency,lvl:255}]} 1',
      info: 'Cela vous donne une pioche en netherite avec efficacité 255',
    },
    {
      text: '/give @p minecraft:netherite_chestplate{Enchantments:[{id:thorns,lvl:255}]} 1',
      info: 'Cela vous donne une armure en netherite avec épine 255',
    },
    {
      text: '/playsound minecraft:ambient.cave hostile @p',
      info: 'Joue un son qui fait peur autour de vous',
    },
    {
      text: '/summon minecraft:fireball ~ ~3 ~ {ExplosionPower:50,direction:[1.0,0.0,0.0]}',
      info: 'Invoque une giga boule de feu à pousser (attention à vous)',
    },
    {
      text: '/summon minecraft:end_crystal ~ ~ ~',
      info: "Invoque une pierre de l'end, sympa pour la déco",
    },
    {
      text: '/give @p tnt{BlockStateTag:{unstable:"true"}} 1',
      info: "Donne une TNT qui explose au lieu de se casser quand on la tape",
    },
    {
      text: '/summon minecraft:pig ~ ~1 ~ {Passengers:[{id:"minecraft:phantom",Silent:1b,NoAI:1b}]}',
      info: "Invoque un cochon avec des ailes",
    },
    {
      text: '/summon minecraft:slime ~ ~ ~ {Size:100}',
      info: "Invoque un slime géant",
    },
    {
      text: '/summon minecraft:giant',
      info: "Invoque un zombie géant",
    },
    {
      text: '/summon minecraft:creeper ~ ~1 ~ {powered:1}',
      info: "Invoque un creeper electrifié",
    },
    {
      text: '/summon minecraft:ender_dragon ~ ~1 ~ {Passengers:[{id:"minecraft:wither"}]}',
      info: "Invoque un giga boss.. bonne chance",
    },
  ];

function CardCommands() {
  const location = useLocation();

  const [randomCommand, setRandomCommand] = React.useState(
    location.state.isJava
      ? commandsJava[Math.floor(Math.random() * commandsJava.length)]
      : commandsBedrock[Math.floor(Math.random() * commandsBedrock.length)]
  );

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/`;
    navigate(path);
  };

  const notify = () =>
    toast.success('Copié', {
      position: 'bottom-left',
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });

  const getRandomCommand = () => {
    let seed = randomCommand;
    while (seed === randomCommand) {
      seed = location.state.isJava
        ? commandsJava[Math.floor(Math.random() * commandsJava.length)]
        : commandsBedrock[Math.floor(Math.random() * commandsBedrock.length)];
    }
    setRandomCommand(seed);
  };

  return (
    <DivCard>
      <div className="display-flex gap-20 align-items-center">
        <img className="width-50" src={ImgCommandBlock}></img>
        <p className="fs-20 font-semibold word-break">Commandes</p>
      </div>

      <p className="margin-top-15 font-medium fs-20">
        Clique sur la commande pour la copier et colle la en jeu en appuyant sur
        “CTRL + V”.
      </p>

      <DivCardParent className="display-flex justify-space-between margin-top-25 gap-35 align-items-center">
        <p className="flex-1 font-bold fs-50 underline cursor-pointer">
          <CopyToClipboard
            text={randomCommand.text}
            onCopy={() => {
              notify();
            }}
          >
            <span className='word-break'>{randomCommand.text}</span>
          </CopyToClipboard>
        </p>
        <DivCardChild>
          <img className="mobile-hide" src={ImgSign}></img>
          <div>
            <p className="fs-25 font-semibold word-break">Informations</p>
            <p className="fs-18 font-medium margin-top-10 word-break">
              {randomCommand.info}
            </p>
          </div>
        </DivCardChild>
      </DivCardParent>

      <div className="display-flex gap-30 justify-content-center mobile-column">
        <ButtonOutline className="font-semibold" onClick={routeChange}>
          Accueil
        </ButtonOutline>
        <ButtonPrimary className="font-semibold" onClick={getRandomCommand}>
          Relancer
        </ButtonPrimary>
      </div>
      <ToastContainer />
    </DivCard>
  );
}

export default CardCommands;
