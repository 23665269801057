import ImgBackgroundNight from '../../assets/background-night.png';
import { useEffect } from 'react';
import CardCommands from '../../components/CardCommands';

function Commandes({ background, updateBackground }) {
  useEffect(() => {
    updateBackground(ImgBackgroundNight);
  }, []);

  return (
    <div className="margin-top-55">
      <CardCommands></CardCommands>
    </div>
  );
}

export default Commandes;
