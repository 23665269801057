import CardChoice from '../../components/CardChoice';
import ImgBackgroundDay from '../../assets/background-day.png';
import { useEffect } from 'react';

function Home({ background, updateBackground }) {
  useEffect(() => {
    updateBackground(ImgBackgroundDay);
  }, []);

  return (
    <div className="margin-top-55">
      <CardChoice></CardChoice>
    </div>
  );
}

export default Home;
