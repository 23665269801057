import { Link } from 'react-router-dom';
import styled from 'styled-components';
import RandomCraftLogo from '../../assets/randomcraft.png';

const HomeLogo = styled.img`
  height: 67px;
  width: 67px;
`;

const NavContainer = styled.nav`
  padding: 30px 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 30px;
  }
`;

const DivFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const TextLogo = styled.p`
  margin: 0px;
  font-size: 25px;
  color: ${(props) => (props.isBackgroundDay === true ? 'black' : 'white')};
  ${(props) => props.$isSecondary && `font-size: 15px;`}
  transition: all 0.7s linear;
`;

const ContactStyle = styled.a`
  color: ${(props) => (props.isBackgroundDay === true ? 'black' : 'white')};
  text-decoration: none;
  font-size: 20px;
  transition: all 0.7s linear;
`;

const ButtonOutline = styled.a`
  color: ${(props) => (props.isBackgroundDay === true ? 'black' : 'white')};
  text-decoration: none;
  font-size: 20px;
  border-radius: 20px;
  border: solid 1px
    ${(props) => (props.isBackgroundDay === true ? 'black' : 'white')};
  padding: 16px 55px;
  transition: all 0.7s linear;
`;

function Header({ isBackgroundDay }) {
  return (
    <NavContainer>
      <DivFlex>
        <Link to="/">
          <HomeLogo src={RandomCraftLogo} />
        </Link>
        <div>
          <TextLogo
            className="font-extrabold"
            isBackgroundDay={isBackgroundDay}
          >
            RandomCraft
          </TextLogo>
          <TextLogo $isSecondary isBackgroundDay={isBackgroundDay}>
            Par le Youtubeur Hydrogen
          </TextLogo>
        </div>
      </DivFlex>

      <DivFlex>
        <ContactStyle
          className="font-medium"
          href="https://discord.gg/gDRhevCmvH"
          target="_blank"
          isBackgroundDay={isBackgroundDay}
        >
          Contact
        </ContactStyle>
        <ButtonOutline
          className="font-medium"
          href="https://www.youtube.com/channel/UCZP7VNRzRJvD3C36kPlTx9w"
          target="_blank"
          isBackgroundDay={isBackgroundDay}
        >
          Tutoriel
        </ButtonOutline>
      </DivFlex>
    </NavContainer>
  );
}

export default Header;
