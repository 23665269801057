import React, { useState } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import Header from './components/Header';
import Title from './components/Title';

import Error from './pages/Error';
import Commandes from './pages/Commandes';

import styled from 'styled-components';

import ImgBackgroundDay from './assets/background-day.png';
import Seeds from './pages/Seeds';

const DivBackground = styled.div`
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  transition: all 0.7s linear;
`;

function App() {
  const [background, updateBackground] = useState(ImgBackgroundDay);

  const isBackgroundDay = background === ImgBackgroundDay ? true : false;
  return (
    <React.StrictMode>
      <Router>
        <DivBackground style={{ backgroundImage: 'url(' + background + ')' }}>
          <Header isBackgroundDay={isBackgroundDay} />

          <div className="padding-30">
            <Title isBackgroundDay={isBackgroundDay} />

            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Home
                    background={background}
                    updateBackground={updateBackground}
                  />
                }
              />

              <Route
                exact
                path="/seeds"
                element={
                  <Seeds
                    background={background}
                    updateBackground={updateBackground}
                  />
                }
              />

              <Route
                exact
                path="/commands"
                element={
                  <Commandes
                    background={background}
                    updateBackground={updateBackground}
                  />
                }
              />

              <Route exact path="/*" element={<Error />} />
            </Routes>
          </div>
        </DivBackground>
      </Router>
    </React.StrictMode>
  );
}

export default App;
