import styled from 'styled-components';

import ImgSign from '../../assets/sign.png';
import ImgGrassBlock from '../../assets/grass-block.png';
import ImgSeedsBedrock1 from '../../assets/seeds/seeds_bedrock_1.png';
import ImgSeedsBedrock2 from '../../assets/seeds/seeds_bedrock_2.png';
import ImgSeedsBedrock3 from '../../assets/seeds/seeds_bedrock_3.png';
import ImgSeedsBedrock4 from '../../assets/seeds/seeds_bedrock_4.png';
import ImgSeedsBedrock5 from '../../assets/seeds/seeds_bedrock_5.png';
import ImgSeedsJava1 from '../../assets/seeds/seeds_java_1.png';
import ImgSeedsJava2 from '../../assets/seeds/seeds_java_2.png';
import ImgSeedsJava3 from '../../assets/seeds/seeds_java_3.png';
import ImgSeedsJava4 from '../../assets/seeds/seeds_java_4.png';
import ImgSeedsJava5 from '../../assets/seeds/seeds_java_5.png';

import { useNavigate, useLocation } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';

import React, { useState } from 'react';

const DivCard = styled.div`
  background: #ffffffcc;
  width: 70%;
  margin: auto;
  border-radius: 25px;
  padding: 30px 40px;
`;

const DivCardParent = styled.div`
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

const DivCardChild = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${(props) => props.imgSeed});
  background-position: center;
  background-size: cover;
  color: white;
  border-radius: 25px;
  flex: 1;
  width: 100%;
  padding: 25px 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
`;

const ButtonPrimary = styled.button`
  background: #1233b5;
  border-radius: 25px;
  margin-top: 45px;
  font-size: 30px;
  border: none;
  color: white;
  width: 250px;
  max-width: 100%;
  height: 65px;
  cursor: pointer;
  &:hover {
    background: #001a83;
  }
`;

const ButtonOutline = styled.button`
  background: none;
  border-radius: 25px;
  margin-top: 45px;
  font-size: 30px;
  border: solid 1px black;
  color: black;
  width: 250px;
  max-width: 100%;
  height: 65px;
  cursor: pointer;
  &:hover {
    background: #ffffff;
  }
`;

const seedsJava = [
  {
    text: '-1 820 780 390',
    info: 'Grande montagne coupé par un océan',
    img: ImgSeedsJava1,
  },
  {
    text: '1 278 040 446',
    info: '(400/70/-740) Magnifique lac entouré de montagne',
    img: ImgSeedsJava2,
  },
  {
    text: '-4 203 072 258 057 407 798',
    info: 'Cité des abimes sous le spawn',
    img: ImgSeedsJava3,
  },
  {
    text: '7 252 205 219 561 385 694',
    info: 'Multiples cité des abimes sous le spawn',
    img: ImgSeedsJava4,
  },
  {
    text: '-4 783 816 485 741 565 927',
    info: 'Spawn avec 3 biomes marais de mangrove + village',
    img: ImgSeedsJava5,
  },
];

const seedsBedrock = [
  {
    text: '565 535 403 532 980 236',
    info: 'Spawn dans une cité des abimes',
    img: ImgSeedsBedrock1,
  },
  {
    text: '-156 227 665',
    info: '(-700/-50/-620) Cité des abimes mélangé avec une lush cave et une mineshaft',
    img: ImgSeedsBedrock2,
  },
  {
    text: '348 722 287 802 000 751',
    info: "Marais de mangrove au spawn + portail de l'end sous le spawn",
    img: ImgSeedsBedrock3,
  },
  {
    text: '5 454 173 288 014 282 276',
    info: 'Spawn dans une marais de mangrove + maison de illageois',
    img: ImgSeedsBedrock4,
  },
  {
    text: '5 974 902 417 451 286 596',
    info: 'Multiples cité des abimes au spawn + (-700/100/-200) Montagne avec une cité des abimes',
    img: ImgSeedsBedrock5,
  },
];

function CardSeeds() {
  const location = useLocation();

  const [randomSeeds, setRandomSeed] = React.useState(
    location.state.isJava
      ? seedsJava[Math.floor(Math.random() * seedsJava.length)]
      : seedsBedrock[Math.floor(Math.random() * seedsBedrock.length)]
  );

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/`;
    navigate(path);
  };

  const notify = () =>
    toast.success('Copié', {
      position: 'bottom-left',
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });

  const getRandomSeed = () => {
    let seed = randomSeeds;
    while (seed === randomSeeds) {
      seed = location.state.isJava
        ? seedsJava[Math.floor(Math.random() * seedsJava.length)]
        : seedsBedrock[Math.floor(Math.random() * seedsBedrock.length)];
    }
    setRandomSeed(seed);
  };

  return (
    <DivCard>
      <div className="display-flex gap-20 align-items-center">
        <img className="width-50" src={ImgGrassBlock}></img>
        <p className="fs-20 font-semibold word-break">Seeds</p>
      </div>

      <p className="margin-top-15 font-medium fs-20">
        Clique sur le numéro (la seed) pour la copier et colle la en jeu en
        appuyant sur “CTRL + V”. (Nouveau monde -&gt; Plus d’options -&gt;
        Coller dans “Graine pour la génération du monde”)
      </p>

      <DivCardParent className="display-flex justify-space-between margin-top-25 gap-35 align-items-center">
        <p className="flex-1 font-bold fs-50 underline cursor-pointer">
          <CopyToClipboard
            text={randomSeeds ? randomSeeds.text : ''}
            onCopy={() => {
              notify();
            }}
          >
            <span className='word-break'>{randomSeeds ? randomSeeds.text : ''}</span>
          </CopyToClipboard>
        </p>
        <DivCardChild imgSeed={randomSeeds ? randomSeeds.img : ''}>
          <img className="mobile-hide" src={ImgSign}></img>
          <div>
            <p className="fs-25 font-semibold word-break">Informations</p>
            <p className="fs-18 font-medium margin-top-10 word-break">
              {randomSeeds ? randomSeeds.info : ''}
            </p>
          </div>
        </DivCardChild>
      </DivCardParent>

      <div className="display-flex gap-30 justify-content-center mobile-column">
        <ButtonOutline className="font-semibold" onClick={routeChange}>
          Accueil
        </ButtonOutline>
        <ButtonPrimary className="font-semibold" onClick={getRandomSeed}>
          Relancer
        </ButtonPrimary>
      </div>

      <ToastContainer />
    </DivCard>
  );
}

export default CardSeeds;
