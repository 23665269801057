import ImgBackgroundNight from '../../assets/background-night.png';
import { useEffect } from 'react';
import CardSeeds from '../../components/CardSeeds';

function Seeds({ background, updateBackground }) {
  useEffect(() => {
    updateBackground(ImgBackgroundNight);
  }, []);

  return (
    <div className="margin-top-55">
      <CardSeeds></CardSeeds>
    </div>
  );
}

export default Seeds;
